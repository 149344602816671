.card {
    background-color: #1c1c1c;
    border-radius: 12px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s;
    color: white;
}

.card h3 {
    margin-bottom: 10px;
    color: white;
}

.card button {
    background-color: #FF5722;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.card button:hover {
    background-color: #E64A19;
}

.card:hover {
    transform: translateY(-5px);
}