/* WishlistItem.css */

.wishlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Center the cards */
    padding: 20px;
}

.wishlist-item {
    background-color: #1c1c1c;
    border-radius: 12px;
    padding: 20px;
    margin: 15px;
    width: 250px;
    /* Increased width for larger card size */
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.wishlist-item img {
    display: block;
    max-width: 100%;
    max-height: 150px;
    /* Set a max height to maintain aspect ratio */
    width: auto;
    height: auto;
    margin: 0 auto 15px;
    border-radius: 8px;
}

.wishlist-item p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 100%;
    margin: 10px 0;
}

.wishlist-item a {
    color: #FF5722;
    text-decoration: none;
    font-weight: bold;
}

.wishlist-item a:hover {
    color: #E64A19;
    text-decoration: underline;
}